import { jarallax, jarallaxElement, jarallaxVideo } from "jarallax";

import { newsletterInit } from "./newsletter";

const mailchimpURL =
  "https://tpaction.us3.list-manage.com/subscribe/post-json?u=96d98b0f15df72738c2604ed2&amp;id=6cd41ca65f&c=?";

jarallaxVideo();
jarallaxElement();

jarallax(document.querySelectorAll(".jarallax"), {
  disableParallax: () => /iPad|iPhone|iPod|Android/.test(navigator.userAgent),
  disableVideo: () => /iPad|iPhone|iPod|Android/.test(navigator.userAgent),
});

newsletterInit(mailchimpURL);

$(".open-sidebar").on("click", function () {
  $(".sidebar").addClass("opened");
  $(".sidebar .sidebar-content").removeClass("hidden-menu");
  $("body").addClass("no-scroll");
});

$(".sidebar-close").on("click", function () {
  $(".sidebar .sidebar-content").addClass("hidden-menu");
  setTimeout(() => {
    $(".sidebar").removeClass("opened");
    $("body").removeClass("no-scroll");
  }, 500);
});

$(".sidebar .overlay").on("click", function () {
  $(".sidebar .sidebar-content").addClass("hidden-menu");
  setTimeout(() => {
    $(".sidebar").removeClass("opened");
    $("body").removeClass("no-scroll");
  }, 500);
});

$(".sidebar .sidebar-content li.item").on("click", function () {
  if ($(this).hasClass("isOpen")) {
    $(this).removeClass("isOpen");
  } else {
    $(".sidebar .sidebar-content li.item").removeClass("isOpen");
    $(this).addClass("isOpen");
  }
});

$(".menu-item-has-children a").on("click", function () {
  $(this).parent().toggleClass("open");
});

$(".careers .jobs-list a").on("click", function () {
  var index = $(this).parent().index();

  $(".careers .jobs").hide();
  $(".careers .back-to-jobs-list").show();
  $(".careers .jobs-description li.job-description").hide().eq(index).show();
});

$(".endorsements .jobs-list a").on("click", function () {
  var tab = $(this).data('tab');

  $(".endorsements .jobs").hide();
  $(".endorsements .back-to-jobs-list").show();
  $(".endorsements .jobs-description li.job-description").hide();
  $(".endorsements .jobs-description li[data-tab=" + tab + "]").show();
});

$(".back-to-jobs-list").on("click", function () {
  $(".back-to-jobs-list").hide();
  $(".jobs-description li.job-description").hide();
  $(".jobs").show();
});


$(function () {
  $('#ex1').modal();
  // console.log('aqui')
  // $.modal.open();
})