import { capitalize, lowercase, isEmail, isFunctionalKey, isFullname, isPhone, isText, isNumber } from './helpers';

const $newsletter = document.querySelector('.form__newsletter');
const $newsletterField = document.querySelectorAll('.form__field');
const $newsletterInput = document.querySelectorAll('.form__field-input');
const $newsletterInputName = document.querySelector('input[name="NAME"]');
const $newsletterInputFirstName = document.querySelector('input[name="FNAME"]');
const $newsletterInputLastName = document.querySelector('input[name="LNAME"]');
const $newsletterInputEmail = document.querySelector('input[name="EMAIL"]');
const $newsletterInputPhone = document.querySelector('input[name="PHONE"]');
const $newsletterButtonSend = document.querySelector('button[name="SEND"]');

const _newsletterFieldDisable = 'form__field--disabled';
const _newsletterFieldError = 'form__field--error';
const _newsletterFieldValid = 'form__field--valid';
const _newsletterButtonActive = 'form__button--active';
const _newsletterButtonLoading = 'form__button--loading';
const _newsletterButtonError = 'form__button--error';
const _newsletterButtonSuccess = 'form__button--success';

let _newsletterUrl = null;
let _newsletterData = null;
let _newsletterDataArray = null;
let _newsletterSending = null;

export const newsletterInit = newsletterUrl => {
  if (!$newsletter.length) return;

  _newsletterUrl = newsletterUrl;

  $($newsletterInputPhone).inputmask('999-999-9999');

  $newsletterInput.forEach(input => input.addEventListener('change', newsletterInputsChange));
  $newsletterInputName.addEventListener('keydown', newsletterInputsText);
  $newsletterInputPhone.addEventListener('keydown', newsletterInputsNumber);
  $newsletterInputPhone.addEventListener('focusout', newsletterInputsChange);
  $newsletterButtonSend.addEventListener('click', newsletterButtonSend);
}

const newsletterReset = () => {
  _newsletterSending = false;

  newsletterInputsClean();
  newsletterInputsEnable();
  newsletterButtonSendEnable();
}

const newsletterSend = () => {
  $newsletterInputFirstName.value = $newsletterInputName.value.split(' ').splice(0, 1).join(' ');
  $newsletterInputLastName.value = $newsletterInputName.value.split(' ').splice(1).join(' ');

  _newsletterData = $('.form__newsletter').serialize();
  _newsletterDataArray = $('.form__newsletter').serializeArray();
  _newsletterSending = true;

  const NAME = _newsletterDataArray.find(item => item.name === "NAME").value.split(" ");

  _newsletterDataArray.map(item => {
    item.name === "FNAME" && (item.value = NAME[0]);
    item.name === "LNAME" && (item.value = NAME[1]);
  });

  newsletterInputsDisable();
  newsletterButtonSendDisable();
  newsletterButtonSendLoading();

  $.ajax({
    type: 'POST',
    url: _newsletterUrl,
    cache: false,
    contentType: 'application/json; charset=utf-8',
    crossDomain: true,
    data: jQuery.param(_newsletterDataArray),
    dataType: 'json',
    error: newsletterSendError,
    success: newsletterSendResult
  });
}

const newsletterSendResult = data => {
  return data.result === 'success' ? newsletterSendSuccess() : newsletterSendError();
}

const newsletterSendError = () => {
  newsletterButtonSendDone();
  newsletterButtonSendError();

  setTimeout(newsletterReset, 2000);
}

const newsletterSendSuccess = () => {
  newsletterButtonSendDone();
  newsletterButtonSendSuccess();

  setTimeout(newsletterReset, 2000);
}

const newsletterButtonSend = e => {
  e.preventDefault();

  if (!newsletterInputsValidate() || _newsletterSending) return;

  newsletterSend();
}

const newsletterButtonSendDisable = () => {
  $newsletterButtonSend.classList.add(_newsletterButtonActive);
}

const newsletterButtonSendEnable = () => {
  $newsletterButtonSend.classList.remove(
    _newsletterButtonActive,
    _newsletterButtonError,
    _newsletterButtonSuccess
  );
}

const newsletterButtonSendDone = () => {
  const loading = $newsletterButtonSend.querySelector('.lds-default');

  $newsletterButtonSend.classList.remove(_newsletterButtonLoading);
  $newsletterButtonSend.removeChild(loading);
}

const newsletterButtonSendLoading = () => {
  const loading = '<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';

  $newsletterButtonSend.classList.add(_newsletterButtonLoading);
  $newsletterButtonSend.insertAdjacentHTML('beforeend', loading);
}

const newsletterButtonSendError = () => {
  $newsletterButtonSend.classList.add(_newsletterButtonError);
}

const newsletterButtonSendSuccess = () => {
  $newsletterButtonSend.classList.add(_newsletterButtonSuccess);
}

const newsletterInputsChange = () => {
  newsletterInputsValidate();
}

const newsletterInputsClean = () => {
  $newsletterField.forEach(field => {
    const input = field.querySelector('input');
    const inputPlaceholder = input.getAttribute('data-input-placeholder');

    input.blur();
    input.setAttribute('placeholder', inputPlaceholder);
    input.value = '';

    field.classList.remove(_newsletterFieldError);
    field.classList.remove(_newsletterFieldValid);
  });
}

const newsletterInputsDisable = () => {
  $newsletterField.forEach(field => field.classList.add(_newsletterFieldDisable));
}

const newsletterInputsEnable = () => {
  $newsletterField.forEach(field => field.classList.remove(_newsletterFieldDisable));
}

const newsletterInputsError = input => {
  const field = input.parentNode;

  field.classList.remove(_newsletterFieldValid);
  field.classList.add(_newsletterFieldError);
}

const newsletterInputsErrorPlaceholder = input => {
  const errorPlaceholder = input.getAttribute('data-input-placeholder-error');

  input.setAttribute('placeholder', errorPlaceholder);
  input.focus();
}

const newsletterInputsValid = input => {
  const field = input.parentNode;

  field.classList.remove(_newsletterFieldError);
  field.classList.add(_newsletterFieldValid);
}

const newsletterInputsNumber = e => {
  const code = e.keyCode;
  const char = String.fromCharCode(code);

  if (isFunctionalKey(code)) return;
  if (!isNumber(char)) return e.preventDefault();
}

const newsletterInputsText = e => {
  const code = e.keyCode;
  const char = String.fromCharCode(code);

  if (isFunctionalKey(code)) return;
  if (!isText(char)) return e.preventDefault();
}

const newsletterInputsValidate = () => {
  const inputName = $newsletterInputName;
  const inputNameValue = capitalize(inputName.value);
  const inputEmail = $newsletterInputEmail;
  const inputEmailValue = lowercase(inputEmail.value);
  const inputPhone = $newsletterInputPhone;
  const inputPhoneValue = capitalize(inputPhone.value);

  if (!inputNameValue) {
    newsletterInputsErrorPlaceholder(inputName);
    return false;
  } else if (!isFullname(inputNameValue)) {
    newsletterInputsError(inputName);
    return false;
  }
  newsletterInputsValid(inputName);

  if (!inputEmailValue) {
    newsletterInputsErrorPlaceholder(inputEmail);
    return false;
  } else if (!isEmail(inputEmailValue)) {
    newsletterInputsError(inputEmail);
    return false;
  }
  newsletterInputsValid(inputEmail);

  if (!inputPhoneValue) {
    newsletterInputsErrorPlaceholder(inputPhone);
    return false;
  } else if (!isPhone(inputPhoneValue)) {
    newsletterInputsError(inputPhone);
    return false;
  }
  newsletterInputsValid(inputPhone);

  return true;
}

export const newsletterSubmitClick = callback => {
  $newsletterButtonSend.addEventListener('click', e => callback(e));
}
