export const capitalize = string => {
  return lowercase(string).replace(/(?:^|\s)\S/g, c => c.toUpperCase());
}

export const lowercase = string => {
  return string.toLowerCase();
}

export const uppercase = string => {
  return string.toUpperCase();
}

export const isEmail = email => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const isFullname = fullname => {
  const isFull = fullname.split(' ').length > 1;
  const isValid = isName(fullname);
  
  return isFull && isValid;
}

export const isFunctionalKey = code => {
  return code === 8 || code === 9 || code === 13 || code === 37 || code === 38 || code === 39 || code === 40 || code === 46;
}

export const isName = name => {
  const isEmpty = !name.length;
  const isShort = name.length < 2;
  const hasInvalidChars = !(/^[a-zA-Z\u00C0-\u00FF ]+$/g.test(name));

  return !isEmpty && !isShort && !hasInvalidChars;
}

export const isNumber = number => {
  return /^[0-9]+$/g.test(number);
}

export const isPhone = phone => {
  return /^[0-9]{3}-[0-9]{3}-[0-9]{4}/g.test(phone);
}

export const isText = text => {
  return /^[a-zA-Z\u00C0-\u00FF ]+$/g.test(text);
}

export const isSpecialChar = char => {
  return /^[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]+$/g.test(char);
}

export const isMobileSize = () => window.innerWidth < 1024;
